import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _33b1b976 = () => interopDefault(import('../pages/attendance/index.vue' /* webpackChunkName: "pages/attendance/index" */))
const _575fbbd2 = () => interopDefault(import('../pages/doccompany/index.vue' /* webpackChunkName: "pages/doccompany/index" */))
const _f672d550 = () => interopDefault(import('../pages/docprivate/index.vue' /* webpackChunkName: "pages/docprivate/index" */))
const _43e8897a = () => interopDefault(import('../pages/event/index.vue' /* webpackChunkName: "pages/event/index" */))
const _24eadffe = () => interopDefault(import('../pages/holiday/index.vue' /* webpackChunkName: "pages/holiday/index" */))
const _4486bd6c = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "pages/home/index" */))
const _7373daae = () => interopDefault(import('../pages/overtime/index.vue' /* webpackChunkName: "pages/overtime/index" */))
const _4a497fdc = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _611a4ad6 = () => interopDefault(import('../pages/reimbursment/index.vue' /* webpackChunkName: "pages/reimbursment/index" */))
const _d60199fe = () => interopDefault(import('../pages/report/index.vue' /* webpackChunkName: "pages/report/index" */))
const _6bb93c83 = () => interopDefault(import('../pages/sallary/index.vue' /* webpackChunkName: "pages/sallary/index" */))
const _31f49ef8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/attendance",
    component: _33b1b976,
    name: "attendance"
  }, {
    path: "/doccompany",
    component: _575fbbd2,
    name: "doccompany"
  }, {
    path: "/docprivate",
    component: _f672d550,
    name: "docprivate"
  }, {
    path: "/event",
    component: _43e8897a,
    name: "event"
  }, {
    path: "/holiday",
    component: _24eadffe,
    name: "holiday"
  }, {
    path: "/home",
    component: _4486bd6c,
    name: "home"
  }, {
    path: "/overtime",
    component: _7373daae,
    name: "overtime"
  }, {
    path: "/profile",
    component: _4a497fdc,
    name: "profile"
  }, {
    path: "/reimbursment",
    component: _611a4ad6,
    name: "reimbursment"
  }, {
    path: "/report",
    component: _d60199fe,
    name: "report"
  }, {
    path: "/sallary",
    component: _6bb93c83,
    name: "sallary"
  }, {
    path: "/",
    component: _31f49ef8,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
